// For keeping constants like base URLs and other configurations.

export const BASE_URL = "https://api.okgini.com/api"
export const TIMEOUT = 10000;  // e.g., 10 seconds timeout


export const CODE_LANGUAGE_TO_STRING = {
	en: 'English', // English
	de: 'Deutsch', // Deutsch (German)
	fr: 'French', // French
	it: 'Italian', // Italian
	ru: 'Russian', // Russian
	el: 'Greek', // Greek
	uk: 'Português', // Ukrainian
	es: 'Spanish', // Spanish
	du: 'Dutch', // Dutch
	cs: 'Czech', // Czech
	pl: 'Polish', // Polish
	he: 'Hebrew', // Hebrew
	ar: 'عربي',  // Arabic
	et: 'Estonian', // Estonian
	lt: 'Lithuanian', // Lithuanian
	tr: 'Turkish', // Turkish
	nl: 'Dutch', // Dutch (again, as 'du' is not standard)
	pt: 'Português' // Portuguese
};
