import React, { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import { useSelector } from 'react-redux';
import useFetchCustomTheme from './hooks/useFetchCustomTheme';
import { createCustomTheme } from './theme/theme';
import Loader from './components/common/Loader';
import TagManager from 'react-gtm-module';

const config = {
	lrg: {
		favicon: '/favicons/lrg.ico',
		title: 'La Réserve',
		appleIcon: '/img/lrg/apple-touch-lrg.png',
		manifest: {
			short_name: 'La Réserve',
			name: 'La Réserve',
			icons: [
				{ src: '/img/lrg/lrg192x192.png', sizes: '192x192', type: 'image/png' },
				{ src: '/img/lrg/lrg512x512.png', sizes: '512x512', type: 'image/png' },
				{ src: '/img/lrg/lrg512x512.png', sizes: '512x512', type: 'image/png', purpose: 'maskable' },
			],
			display: 'standalone',
			theme_color: '#956F56',
			background_color: '#FFFFFF',
		},
	},
	default: {
		favicon: '/favicons/favicon.ico',
		title: 'Exclusivi - Web Application',
		appleIcon: '/img/apple-touch-exclusivi.png',
		manifest: {
			short_name: 'Exclusivi',
			name: 'Exclusivi - Web Application',
			icons: [
				{ src: '/img/exclusivi192x192.png', sizes: '192x192', type: 'image/png' },
				{ src: 'img/exclusivi512x512.png', sizes: '512x512', type: 'image/png' },
				{ src: '/img/exclusivi512x512.png', sizes: '512x512', type: 'image/png', purpose: 'maskable' },
			],
			display: 'standalone',
			theme_color: '#000000',
			background_color: '#FFFFFF',
		},
	},
};

const setFavicon = (faviconPath) => {
	const link = document.querySelector("link[rel='icon']") || document.createElement('link');
	link.type = 'image/x-icon';
	link.rel = 'icon';
	link.href = `${process.env.PUBLIC_URL}${faviconPath}`;
	document.getElementsByTagName('head')[0].appendChild(link);
};

const setAppleTouchIcon = (iconPath) => {
	const link = document.querySelector("link[rel='apple-touch-icon']") || document.createElement('link');
	link.rel = 'apple-touch-icon';
	link.href = `${process.env.PUBLIC_URL}${iconPath}`;
	document.getElementsByTagName('head')[0].appendChild(link);
};

const setTitle = (title) => {
	document.title = title;
};

const generateManifest = (manifest, accommodation) => {
	const origin = window.location.origin;
	return {
		...manifest,
		start_url: `${origin}/${accommodation}/`,
		icons: manifest.icons.map((icon) => ({
			...icon,
			src: `${origin}${icon.src}`,
		})),
	};
};

const setDynamicManifest = (manifestData) => {
	const stringManifest = JSON.stringify(manifestData);
	const blob = new Blob([stringManifest], { type: 'application/manifest+json' });
	const manifestURL = URL.createObjectURL(blob);
	document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);
};

const RootComponent = () => {
	const accommodation = window.location.pathname.split('/')[1];
	const googleTagManagerId = useSelector((state) => state.properties.googleTagManagerId);
	const { theme: customTheme, loadedFontNames, loading } = useFetchCustomTheme(accommodation);
	const [theme, setTheme] = useState(createCustomTheme());
	
	useEffect(() => {
		if (!loading && customTheme) {
			setTheme(createCustomTheme(customTheme, loadedFontNames));
		}
	}, [customTheme, loading]);
	
	useEffect(() => {
		const settings = config[accommodation] || config.default;
		setFavicon(settings.favicon);
		setTitle(settings.title);
		setAppleTouchIcon(settings.appleIcon);
		setDynamicManifest(generateManifest(settings.manifest, accommodation));
	}, [accommodation]);
	
	useEffect(() => {
		if (googleTagManagerId) {
			TagManager.initialize({ gtmId: googleTagManagerId.trim() });
		}
	}, [googleTagManagerId]);
	
	if (loading) {
		return <Loader />;
	}
	
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	);
};

export default RootComponent;
