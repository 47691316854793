import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper, postHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";
import {setLocalStorageWithTimer} from "../../general/util";
import {createEncryptedData} from "../../general/encryptionUtil";

export const fetchUser = createAsyncThunk(
	'user/fetchUser',
	async ({accommodation, type}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/launcher/info`,
				{Authorization: `token ${type}`});
			dispatch(setIsTestUser(
				response.user.reservationNumber === response.user.firstName &&
				response.user.firstName === response.user.roomNumber
			))
			dispatch(setToken(type));
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error fetching user');
		}
	}
);

export const send2FACode = createAsyncThunk(
	'user/send2FACode',
	async ({accommodation, mobile}, {rejectWithValue, dispatch, getState}) => {
		try {
			const isLogismos = getState().protectedRoute.isLogismos;
			
			dispatch(setMobile(mobile));
			
			if (isLogismos) {
				const encryptedData = await createEncryptedData({
					accommodationId: accommodation,
					mobileNumber: mobile
				})
				const response = await postHelper(`${BASE_URL}/routee/sendCodeToCustomer/secure`,
					{
						encryptedData
					}, {
						Authorization: `lid ${accommodation}`, 'Content-Type': 'application/json',
					});
				return response;
			} else {
				const response = await getHelper(`${BASE_URL}/routee/sendCodeToCustomer/mobileNumber/${mobile}/accommodationId/${accommodation}`,
					{Authorization: `lid ${accommodation}`});
				return response;
			}
		} catch (error) {
			return rejectWithValue(error.message || 'Error initiating 2FA');
		}
	}
);

export const verify2FACode = createAsyncThunk(
	'user/verify2FACode',
	async ({accommodation, payload}, {rejectWithValue, getState, dispatch}) => {
		const isLogismos = getState().protectedRoute.isLogismos;
		try {
			const mobile = getState().user.mobile;
			const response = await postHelper(isLogismos ? `${BASE_URL}/routee/verifyCode/secure` : `${BASE_URL}/routee/verifyCode`,
				isLogismos ? {encryptedData: await createEncryptedData(payload)} : payload,
				{
					Authorization: `token ${accommodation}-en`,
					"Content-Type": "application/json",
				},);
			
			if (response === true) {
				dispatch(setAuthInProgress(false));
				setLocalStorageWithTimer(`verifiedMobile-${accommodation}`, mobile, 900000)
			}
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error verifying 2FA');
		}
	}
);

export const createAccount = createAsyncThunk(
	'user/createAccount',
	async ({accommodation, payload}, {rejectWithValue}) => {
		try {
			const response = await postHelper(`${BASE_URL}/loyaltyClub/acm/${accommodation}`, payload, {
				"Content-Type": "application/json",
				"Content-Length": `${payload.length}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const fetchUserByRoomNumber = createAsyncThunk(
	'user/fetchUserByRoomNumber',
	async ({accommodation, roomNumber, firstFourLettersOfLastNameOrDepartureDate}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/user/infoByRoomNumberAndLastNameOrDepartureDate/${roomNumber}/${firstFourLettersOfLastNameOrDepartureDate}`, {
				Authorization: `lid ${accommodation}`
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);


export const fetchUserByReservationNumber = createAsyncThunk(
	'user/fetchUserByReservationNumber',
	async ({accommodation, reservationNumber}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/guests/findByReservationNumber/${reservationNumber}`, {
				Authorization: `lid ${accommodation}`,
			});
			dispatch(setIsTestUser(response.isTestUser || false))
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const fetch2FAUser = createAsyncThunk(
	'user/fetch2FAUser',
	async ({accommodation, mobile2FA, token}, {getState, dispatch, rejectWithValue}) => {
		const state = getState();
		
		if (state.user.authInProgress) return;
		
		dispatch(setAuthInProgress(true));
		
		const mobile = mobile2FA || getState().user.mobile;
		
		try {
			let token2FA;
			
			if (mobile) {
				
				const tokenExist = await dispatch(checkUserIfExistAndGetToken({mobile, accommodation}));
				
				if (!tokenExist.payload) {
					const pin2FAVerified = getState().user.pin2FAVerified;
					// If user doesn't exist but either pin2FAVerified or mobile Verified is true, start account creation process
					if (pin2FAVerified || mobile2FA) {
						dispatch(setAccountCreatingProcess(true));
					}
					return rejectWithValue("No token found for this mobile number");
				}
				token2FA = tokenExist.payload;
				dispatch(setToken(token2FA));
				
			} else if (token) {
				
				const tokenIsExpired = await dispatch(checkIfTokenHasExpired({accommodation, token}));
				
				if (!tokenIsExpired.payload || tokenIsExpired.payload === true) {
					return rejectWithValue("Token expired");
				}
				token2FA = token;
				dispatch(setToken(token2FA));
			} else {
				return rejectWithValue("No mobile or token provided");
			}
			
			const data = await dispatch(fetchLoyaltyMemberWithToken({
				token: token2FA,
				accommodation
			}));
			
			if (!data) {
				return rejectWithValue("Failed to fetch loyalty member");
			}
			
			localStorage.setItem(`token-${accommodation}`, token2FA);
			
			dispatch(setAuthInProgress(false));
			return data;
		} catch (error) {
			return rejectWithValue(error.message || 'Error logging in with 2FA');
		}
	}
);

export const fetch2FALogismos = createAsyncThunk(
	'user/fetch2FALogismos',
	async ({accommodation, mobile2FA, token}, {getState, dispatch, rejectWithValue}) => {
		const {user, properties} = getState(); // Access relevant parts of the state

		const loyaltyConfig = properties?.loyalty?.values || {};
		
		// Exit if auth is already in progress
		if (user.authInProgress) return;
		
		dispatch(setAuthInProgress(true)); // Set auth process to true
		
		const mobile = mobile2FA || user.mobile;
		let jwtToken = token;
		
		try {
			if (mobile) {
				const jwtAndStatusResponse = await dispatch(
					checkUserIfExistAndGetToken({mobile, accommodation, isLogismos: true})
				);
				
				if (jwtAndStatusResponse.payload) {
					jwtToken = jwtAndStatusResponse.payload.token.replace(/"/g, '');
					dispatch(setJwt(jwtToken));
					localStorage.setItem(`token-${accommodation}`, jwtToken);
					
					const status = jwtAndStatusResponse.payload.status;
					if (status !== 'LINKED') {
						// dispatch(setAuthInProgress(false));
						return rejectWithValue('');
					}
					dispatch(setIsFetchingLogismos(true));
				} else {
					// dispatch(setAuthInProgress(false));
					return rejectWithValue('');
				}
			} else if (!jwtToken) {
				return rejectWithValue('');
			} else {
				dispatch(setJwt(jwtToken));
			}
			
			const data = await dispatch(
				fetchLoyaltyMemberWithToken({
					token: jwtToken,
					accommodation,
					isLogismos: true
				})
			);
			
			if (data?.payload?.loyaltyClub?.logismosInfo?.status !== 'LINKED') {
				dispatch(setAuthInProgress(false));
				return rejectWithValue('Account is not linked with loyalty program.');
			}
			
			if (!data || data?.error?.message === 'Rejected') {
				dispatch(setAuthInProgress(false));
				return rejectWithValue('Failed to fetch loyalty member data.');
			}
			
			// Define loyalty categories based on loyaltyConfig
			const loyaltyCategories = [
				{
					name: loyaltyConfig?.loyaltyCardCategoryName1,
					color: loyaltyConfig?.loyaltyCardCategoryColor1,
					image: loyaltyConfig?.loyaltyCardCategoryImage1,
				},
				{
					name: loyaltyConfig?.loyaltyCardCategoryName2,
					color: loyaltyConfig?.loyaltyCardCategoryColor2,
					image: loyaltyConfig?.loyaltyCardCategoryImage2,
				},
				{
					name: loyaltyConfig?.loyaltyCardCategoryName3,
					color: loyaltyConfig?.loyaltyCardCategoryColor3,
					image: loyaltyConfig?.loyaltyCardCategoryImage3,
				},
				{
					name: loyaltyConfig?.loyaltyCardCategoryName4,
					color: loyaltyConfig?.loyaltyCardCategoryColor4,
					image: loyaltyConfig?.loyaltyCardCategoryImage4,
				},
				{
					name: loyaltyConfig?.loyaltyCardCategoryName5,
					color: loyaltyConfig?.loyaltyCardCategoryColor5,
					image: loyaltyConfig?.loyaltyCardCategoryImage5,
				},
			];
			
			// Find current and next loyalty categories based on membership type
			const membershipType = data.payload?.loyaltyCardCustomerInfo?.logismosPayload?.membershipDescription;
			const currentIndex = loyaltyCategories.findIndex((category) => category.name === membershipType);
			const currentCategory = loyaltyCategories[currentIndex] || null;
			const nextCategory = loyaltyCategories[currentIndex + 1] || null;
			
			localStorage.setItem(`token-${accommodation}`, jwtToken);
			dispatch(setAuthInProgress(false));
			
			return {
				data: {...data.payload.loyaltyClub,...data.payload.loyaltyCardCustomerInfo},
				loyaltyCategories,
				currentCategory,
				nextCategory
			};
		} catch (error) {
			dispatch(setAuthInProgress(false));
			return rejectWithValue(error.message || 'Error logging in with 2FA');
		}
	}
);


export const validateCustomer = createAsyncThunk(
	'user/validateCustomer',
	async ({accommodation, playerId, pin}, {rejectWithValue, getState, dispatch}) => {
		try {
			const jwt = getState().user.jwt;
			const response = await postHelper(`${BASE_URL}/loyaltyCard/customer/validate/secure`,
				{encryptedData: await createEncryptedData({accommodationId: accommodation, player_id: playerId, pin})},
				{
					"Authorization": `token ${jwt}`,
					"Content-Type": "application/json",
				},);
			if (response === "NOT_VALID_CUSTOMER") {
				return rejectWithValue('NOT_VALID_CUSTOMER');
			}
			dispatch(setJwt(response?.replace(/"/g, '')));
			localStorage.setItem(`token-${accommodation}`, response?.replace(/"/g, ''));
			dispatch(setAuthInProgress(false));
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error');
		}
	}
);


export const fetchLoyaltyMemberWithToken = createAsyncThunk(
	'user/fetchLoyaltyMemberWithToken',
	async ({accommodation, token, isLogismos}, {getState, rejectWithValue}) => {
		try {
			const response = await getHelper(isLogismos ? `${BASE_URL}/routee/getLoyaltyMember/secure` : `${BASE_URL}/routee/getLoyaltyMember/token/${token}`, {
				Authorization: isLogismos ? `token ${token}` : `lid ${accommodation}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);


export const checkIfTokenHasExpired = createAsyncThunk(
	'user/checkIfTokenHasExpired',
	async ({accommodation, token}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/routee/checkIfTokenHasExpired/token/${token}`, {
				Authorization: `lid ${accommodation}`,
			});
			return response;
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

export const checkUserIfExistAndGetToken = createAsyncThunk(
	'user/checkUserIfExistAndGetToken',
	async ({accommodation, mobile, isLogismos}, {rejectWithValue, getState}) => {
		try {
			if (isLogismos) {
				const encryptedData = await createEncryptedData({
					accommodationId: accommodation,
					mobileNumber: mobile
				})
				const response = await postHelper(`${BASE_URL}/routee/checkIfExistsAndLogIn/secure`,
					{
						encryptedData
					}, {
						Authorization: `lid ${accommodation}`,
						'Content-Type': 'application/json',
					});
				return response;
			} else {
				const response = await getHelper(`${BASE_URL}/routee/checkIfExistsAndLogIn/mobileNumber/${mobile}/accommodationId/${accommodation}`, {
					Authorization: `lid ${accommodation}`,
				});
				return response;
			}
		} catch (error) {
			return rejectWithValue(error.message || 'Error with room number login');
		}
	}
);

const initialState = {
	isTestUser: false,
	data: null,
	status: 'idle', // 'loading', 'failed', 'succeeded'
	error: null,
	loggedIn: false,
	code2FASent: false,
	pin2FAVerified: undefined,
	mobile: null,
	token: undefined,
	jwt: undefined,
	accountCreationSuccess: undefined,
	accountCreatingProcess: undefined,
	authInProgress: undefined,
	isFetchingLogismos: false,
	loyaltyCategories: [],
	currentCategory: null,
	nextCategory: null
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logoutUser: (state) => {
			state.data = null;
			state.status = 'idle';
			state.error = null;
			state.loggedIn = false;
			state.tecode2FASent = false;
			state.pin2FAVerified = undefined;
			state.mobile = null;
			state.token = undefined;
			state.jwt = undefined;
		},
		setMobile: (state, action) => {
			state.mobile = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setJwt: (state, action) => {
			state.jwt = action.payload;
		},
		setIsFetchingLogismos: (state, action) => {
			state.isFetchingLogismos = action.payload;
		},
		setIsTestUser: (state, action) => {
			state.isTestUser = action.payload;
		},
		setAccountCreatingProcess: (state, action) => {
			state.accountCreatingProcess = action.payload;
		},
		setAuthInProgress: (state, action) => {
			state.authInProgress = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
			})
			.addCase(fetchUser.fulfilled, (state, action) => {
				state.data = action.payload.user;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
			.addCase(send2FACode.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(send2FACode.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(send2FACode.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.code2FASent = action.payload;
			})
			.addCase(fetch2FALogismos.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetch2FALogismos.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
				state.pin2FAVerified = undefined;
				state.data = null;
			})
			.addCase(fetch2FALogismos.fulfilled, (state, action) => {
				if (action.payload?.data && Object.keys(action.payload.data).length > 0) {
					state.data = action.payload?.data;
					state.loggedIn = true;
					state.status = 'succeeded';
					state.error = null;
					state.isFetchingLogismos = false;
					state.loyaltyCategories = action.payload.loyaltyCategories;
					state.currentCategory = action.payload.currentCategory;
					state.nextCategory = action.payload.nextCategory;
				}
			})
			.addCase(validateCustomer.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(validateCustomer.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
				state.pin2FAVerified = undefined;
				state.data = null;
			})
			.addCase(validateCustomer.fulfilled, (state, action) => {
				state.status = 'succeeded';
			})
			.addCase(verify2FACode.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(verify2FACode.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(verify2FACode.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.pin2FAVerified = action.payload;
			})
			.addCase(fetch2FAUser.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetch2FAUser.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
				state.pin2FAVerified = undefined;
				state.data = null;
			})
			.addCase(fetch2FAUser.fulfilled, (state, action) => {
				state.data = action.payload?.payload;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
			.addCase(createAccount.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(createAccount.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
			})
			.addCase(createAccount.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.data = action.payload
				state.accountCreationSuccess = true;
				state.accountCreatingProcess = undefined;
				state.error = null;
			})
			.addCase(fetchUserByReservationNumber.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchUserByReservationNumber.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.payload;
				state.loggedIn = false;
			})
			.addCase(fetchUserByReservationNumber.fulfilled, (state, action) => {
				state.data = action.payload;
				state.status = 'succeeded';
				state.loggedIn = true;
				state.error = null;
			})
	}
});

export const {
	logoutUser,
	setMobile,
	setToken,
	setJwt,
	setIsTestUser,
	setAccountCreatingProcess,
	setAuthInProgress,
	setIsFetchingLogismos
} = userSlice.actions;
export default userSlice.reducer;
