import { createSlice } from '@reduxjs/toolkit';


export const selectCurrentPath = (state) => {
	return state.navigation.navigationStack[state.navigation.navigationStack.length - 1];
};


const initialState = {
	navigationStack: [],
	initialPath: null,
	hideBack: undefined,
	hideFooter: undefined
};

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		pushPath(state, action) {
				state.navigationStack.push(action.payload);
		},
		popPath(state) {
			if (state.navigationStack.length >= 1) {
				state.navigationStack.pop();
			}
		},
		setInitialPath(state, action) {
			state.initialPath = action.payload; // Sets the initial path
		},
		setHideBack(state, action) {
			state.hideBack = action.payload
		},
		setHideFooter(state, action) {
			state.hideFooter = action.payload
		}
	}
});

export const { pushPath, popPath, setInitialPath, setHideBack, setHideFooter} = navigationSlice.actions;
export default navigationSlice.reducer;