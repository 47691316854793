import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";
import {getDate} from "../../general/util";

export const fetchWebMenus = createAsyncThunk(
	'webMenus/fetchWebMenus',
	async ({accommodation, outletId, from = getDate.today(), to = getDate.today()}) => {
		const response = await getHelper(`${BASE_URL}/menu/getWebMenus?outletId=${outletId}&from=${from}&to=${to}`,
			{Authorization:`lid ${accommodation}`});
		return response;
	}
);

const initialState = {
	status: 'idle',
	error: null,
};

const webMensSlice = createSlice({
	name: 'webMenus',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWebMenus.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchWebMenus.fulfilled, (state, action) => {
				state.status = 'succeeded';
				const outletUrlId = action.payload.outlet.urlId;
				state[outletUrlId] = action.payload;
			})
			.addCase(fetchWebMenus.rejected, (state, action) => {
				state.status = 'failed';
			});
	},
});

export default webMensSlice.reducer;
