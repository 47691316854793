import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BASE_URL} from "../general/constants";
import {loadFonts} from "../general/util";
import {createCustomTheme} from "../theme/theme";

const useFetchCustomTheme = (accommodation) => {
	const [theme, setTheme] = useState(null);
	const [loadedFontNames, setLoadedFontNames] = useState({});
	const [loading, setLoading] = useState(true);
	
	useEffect(() => {
		const fetchTheme = async () => {
			try {
				const response = await axios.get(`${BASE_URL}/webAppStructure/properties/acm/${accommodation}`);
				const { id, accommodationId, fonts, ...rest } = response.data;
				let loadedFontNames = {};
				setTheme(rest);
				
				if (fonts?.length > 0) {
					loadedFontNames = await loadFonts(fonts);
					setLoadedFontNames(loadedFontNames);
				}
			} catch (error) {
				// Error handling
			} finally {
				setLoading(false);
			}
		};
		
		fetchTheme();
	}, [accommodation]);
	
	return { theme, loadedFontNames, loading };
};

export default useFetchCustomTheme;
