import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";

export const fetchTranslations = createAsyncThunk(
	'translations/fetchTranslations',
	async ({lang = 'en', accommodation}) => {
		const response = await getHelper(`${BASE_URL}/accommodation/resource/translations?lang=${lang}`,
			{Authorization: `lid ${accommodation}`});  // adjust the URL accordingly
		return response.translationText;
	}
);

const initialState = {
	translationText: {},
	lang: 'en',
	status: 'idle',
	error: null
};

const translationsSlice = createSlice({
	name: 'translations',
	initialState,
	reducers: {
		setLanguage: (state, action) => {
			state.lang = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTranslations.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchTranslations.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.translationText = action.payload.reduce((acc, item) => {
					acc[item.id] = item.text;
					return acc;
				}, {});
			})
			.addCase(fetchTranslations.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const {setLanguage} = translationsSlice.actions;
export default translationsSlice.reducer;
