/**
 * @Author Remountakis Emmanouil
 * How to use crypto
 * Step 1: npm install jsencrypt //preferred version "jsencrypt": "^3.3.2"
 * Step 2: import the function from current file to encrypt your data: import { createEncryptedData } from './helpers/encryptionUtil';
 * Step 3: Use sendEncryptedData function to send your data
 */

import JSEncrypt from "jsencrypt";

const base_url = "https://api.okgini.com";

// Creates new RSA private and public key (use with caution, because you will use that pair from now on. All the previous unfinished encryption will not work)
export async function createNewKey() {
  const response = await fetch(`${base_url}/api/keyStorage/createNewKey`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return await response.json();
}

// Helper function to convert Base64 key to PEM format
function base64ToPem(base64Key, type = 'PUBLIC KEY') {
  const cleanBase64Key = base64Key.replace(/(\r\n|\n|\r)/gm, '');
  const base64Lines = cleanBase64Key.match(/.{1,64}/g).join('\n');
  return `-----BEGIN ${type}-----\n${base64Lines}\n-----END ${type}-----`;
}

async function getPublicKey() {
  const response = await fetch(`${base_url}/api/keyStorage/getPublicKey`);
  if (!response.ok) throw new Error('Network response was not ok');
  const responseText = await response.text();
  const publicKeyBase64 = responseText.replace("Public key (Base64): ", "").replaceAll('"', '').trim();
  const publicKeyPem = base64ToPem(publicKeyBase64);
  return publicKeyPem;
}

export async function createEncryptedData(data) {
  const dataString = JSON.stringify(data);
  const dataSizeInBytes = new TextEncoder().encode(dataString).length;
  if (dataSizeInBytes > 256) throw new Error('Data is too large (limit: 256 bytes for RSA encryption)');
  
  const publicKeyPem = await getPublicKey();
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKeyPem);
  
  const encryptedData = encrypt.encrypt(dataString);
  if (!encryptedData) throw new Error('Encryption failed');
  return encryptedData;
}

export async function sendEncryptedData(encryptedData, url, method = 'POST') {
  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ encryptedData }),
  });
  if (!response.ok) throw new Error('Network response was not ok');
  
  const result = await response.json();
  console.log('Server Response:', result);
  return result;
}
