import {useSelector, useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {
	fetchUser,
	
	fetchUserByReservationNumber,
	fetchUserByRoomNumber, // Assuming this was intended instead of a non-existing action
	send2FACode,
	verify2FACode,
	createAccount,
	logoutUser, fetch2FAUser, fetchLogismosUser, fetch2FALogismos
} from '../redux/slices/userSlice';
import {getLocalStorageWithTimer, getQueryParam} from "../general/util";

export const useAuth = () => {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		
		const {accommodation} = useParams();
		const type = getQueryParam('type');
		const res = getQueryParam('res');
		const logismos = getQueryParam('logismos') === 'true';
		
		const mobile2FA = getLocalStorageWithTimer(`verifiedMobile-${accommodation}`);
		const token2FA = localStorage.getItem(`token-${accommodation}`);
		const loyaltyExist = useSelector(state => Object.keys(state.properties.loyalty).length > 0);
		const {
			data,
			isTestUser,
			loggedIn,
			code2FASent,
			pin2FAVerified,
			status,
			error,
			accountCreationSuccess,
			accountCreatingProcess,
			authInProgress,
			isFetchingLogismos
		} = useSelector(state => state.user);
		
		const loginWithSecretCode = useCallback(() => {
			dispatch(fetchUser({accommodation, type}));
		}, [dispatch, accommodation, type]);
		
		const loginWith2FA = useCallback(() => {
			dispatch(fetch2FAUser({accommodation, mobile2FA, token: token2FA}));
		}, [dispatch, accommodation, mobile2FA, token2FA]);
		
		const loginWith2FALogismos = useCallback(() => {
			dispatch(fetch2FALogismos({accommodation, mobile2FA, token: token2FA}));
		}, [dispatch, accommodation, mobile2FA, token2FA]);
		
		const loginWithReservationNumber = useCallback(() => {
			dispatch(fetchUserByReservationNumber({accommodation, reservationNumber: res}));
		}, [dispatch, accommodation, res]);
		
		
		useEffect(() => {
				// If 'type' is present in the query parameters, attempt to login with the secret code
				// and avoid other login methods
				if (type) {
					if (!loggedIn) {
						loginWithSecretCode();
					}
				} else if (res) {
					loginWithReservationNumber();
				} else {
					// If 'type' is not present, proceed with other login methods
					if (
						((code2FASent && pin2FAVerified)
							|| mobile2FA
							|| token2FA
							|| accountCreationSuccess)
						&& !loggedIn && !accountCreatingProcess && !authInProgress) {
						if (logismos && loyaltyExist) {
							loginWith2FALogismos();
						} else if (!logismos) {
							loginWith2FA();
						}
					}
				}
			}, [type, loggedIn, code2FASent, pin2FAVerified, mobile2FA, token2FA, accountCreationSuccess, loginWithSecretCode, loginWith2FA, authInProgress, loyaltyExist]
		)
		;
		
		
		const loginWithRoomNumber = (roomNumber, firstFourLettersOfLastNameOrDepartureDate) => {
			dispatch(fetchUserByRoomNumber({accommodation, roomNumber, firstFourLettersOfLastNameOrDepartureDate}));
		};
		
		
		const sendTwoFACode = (mobile) => {
			dispatch(send2FACode({accommodation, mobile}));
		};
		
		const verifyTwoFACode = (pinData) => {
			dispatch(verify2FACode({accommodation, payload: pinData}));
		};
		
		const createLoyaltyAccount = (accountData) => {
			dispatch(createAccount({accommodation, payload: accountData}));
		};
		
		const logout = () => {
			dispatch(logoutUser());
			localStorage.removeItem('authType');
			navigate('/login');
		};
		
		
		return {
			data,
			isTestUser,
			loggedIn,
			code2FASent,
			pin2FAVerified,
			status,
			error,
			sendTwoFACode,
			verifyTwoFACode,
			createLoyaltyAccount,
			logout,
			mobile2FA,
			isFetchingLogismos
		};
	}
;
