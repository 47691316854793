import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";

export const fetchLauncherInfo = createAsyncThunk(
	'launcherInfo/fetchLauncherInfo',
	async ({lang, secretCode, accommodation}) => {
		const response = await getHelper(`${BASE_URL}/launcher/info?app=true&lang=${lang}`,
			{Authorization: secretCode ? `token ${secretCode}` : `lid ${accommodation}`});
		return response;
	}
);

export const fetchPopulatorInfo = createAsyncThunk(
	'launcherInfo/fetchPopulatorInfo',
	async ({lang, secretCode, accommodation}) => {
		const response = await getHelper(`${BASE_URL}/populator/accommodation/info?lang=${lang}`,
			{Authorization: secretCode ? `token ${secretCode}` : `lid ${accommodation}`});
		return response;
	}
);

const initialState = {
	user: null,
	applications: [],
	status: 'idle',
	error: null
};

const launcherInfoSlice = createSlice({
	name: 'launcherInfo',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchLauncherInfo.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchLauncherInfo.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.user = action.payload.user;
				state.applications = action.payload.applications;
			})
			.addCase(fetchLauncherInfo.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchPopulatorInfo.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchPopulatorInfo.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.populator = action.payload;
			})
			.addCase(fetchPopulatorInfo.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
	},
});

export default launcherInfoSlice.reducer;
