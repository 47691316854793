import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {getHelper} from "../../general/apiHelper";
import {BASE_URL} from "../../general/constants";

export const fetchWebAppStructure = createAsyncThunk(
	'webAppStructure/fetchWebAppStructure',
	async ({accommodation, screenId = "webAppHomeScreen"}, {rejectWithValue, dispatch}) => {
		try {
			const response = await getHelper(`${BASE_URL}/webAppStructure/acm/${accommodation}/${screenId}`);
			const relevantContents = response.sections
				.flatMap(section => section.contents)
				.filter(content => ['outlet', 'outletList', 'widget', 'screen'].includes(content.contentType));
			const outletIds = [];
			const widgetIds = [];
			const outletListIds = [];
			const screens = [];
	
			relevantContents.forEach(content => {
				if (content.contentType === 'outlet') {
					outletIds.push(content.contentId);
				} else if (content.contentType === 'widget') {
					widgetIds.push(content.contentId);
				} else if (content.contentType === 'outletList') {
					outletListIds.push(content.contentId);
				} else if (content.contentType === 'screen') {
					screens.push(content.contentId);
				}
			})
			
			if (outletIds.length) {
				dispatch(fetchOutlets({accommodation, ids: outletIds.join(",")}))
			}
			if (widgetIds.length) {
				dispatch(fetchWidgets({accommodation, ids: widgetIds.join(",")}))
			}
			if (outletListIds.length) {
				dispatch(fetchOutletList({accommodation, ids: outletListIds.join(",")}))
			}
			if (screens.length) {
				dispatch(fetchScreens({accommodation, screens: screens.join(",")}))
			}
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const fetchOutlets = createAsyncThunk(
	'webAppStructure/fetchOutlets',
	async ({accommodation, ids}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/menu/getOutlets/accommodationId/${accommodation}?ids=${ids}`);
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const fetchOutletList = createAsyncThunk(
	'webAppStructure/fetchOutletList',
	async ({accommodation, ids}, {rejectWithValue}) => {
		try {
			const response = await getHelper(`${BASE_URL}/outletList/getAll?ids=${ids}`, {Authorization: `lid ${accommodation}`});
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchWidgets = createAsyncThunk(
	'webAppStructure/fetchWidgets',
	async ({accommodation, ids}, {rejectWithValue,}) => {
		try {
			const response = await getHelper(`${BASE_URL}/widget/getAll?ids=${ids}`, {Authorization: `lid ${accommodation}`});
			
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const fetchScreens = createAsyncThunk(
	'webAppStructure/fetchScreens',
	async ({accommodation, screens}, {rejectWithValue}) => {
		
		// return [{
		// 	"id": "65f089309d88660001337830",
		// 	"accommodationId": "lrg",
		// 	"screen": "restaurantsBars",
		// 	"name": "Restaurants & Bars",
		// 	"multilingualName": {
		// 		"en": "affflika & Bars",
		// 		"fr": "gallika einai"
		// 	},
		// 	"description": {},
		// 	"extraNote": {},
		// 	"sliceImages": [],
		// 	"portraitImages": [],
		// 	"landscapeImages": [],
		// 	"videos": [],
		// 	"sections": [
		// 		{
		// 			"name": "header",
		// 			"type": "buttons",
		// 			"height": "medium",
		// 			"hidden": false,
		// 			"contents": []
		// 		},
		// 		{
		// 			"name": "highlights",
		// 			"type": "slider",
		// 			"height": "medium",
		// 			"hidden": false,
		// 			"contents": [
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bcfcf4291470001e6bc70",
		// 					"contentName": "Le Tsé Fung: Discover the only Michelin-starred Chinese restaurant in Switzerland. Refined flavors."
		// 				},
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bda99db689a0001b500c1",
		// 					"contentName": "Le Loti: Culinary journey in a cozy and casual atmosphere"
		// 				},
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bdc59db689a0001b506a5",
		// 					"contentName": "Le Café Lauren: Healthy cuisine in an intimate space with a soft and bright atmosphere."
		// 				}
		// 			]
		// 		},
		// 		{
		// 			"name": "tiles",
		// 			"type": "list",
		// 			"height": "medium",
		// 			"hidden": false,
		// 			"contents": [
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bd8971d0c2100014548c9",
		// 					"contentName": "Le Tsé Fung"
		// 				},
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bdac41d0c210001455990",
		// 					"contentName": "Le Loti"
		// 				},
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660bdc991d0c210001455dc6",
		// 					"contentName": "Le Café Lauren"
		// 				}
		// 			]
		// 		},
		// 		{
		// 			"name": "footer",
		// 			"type": "buttons",
		// 			"height": "medium",
		// 			"hidden": false,
		// 			"contents": [
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "65fafd81558a5f0001d833cc",
		// 					"contentName": "What's On",
		// 					"iconName": "AccessAlarms"
		// 				},
		// 				{
		// 					"contentName": "Home",
		// 					"iconName": "Home"
		// 				},
		// 				{
		// 					"contentType": "widget",
		// 					"contentId": "660beed5b983920001d8a234",
		// 					"contentName": "My Bookings",
		// 					"iconName": "BookmarkBorder"
		// 				}
		// 			]
		// 		}
		// 	]
		// }]
		try {
			const response = await getHelper(`${BASE_URL}/webAppStructure/acm/${accommodation}/getAll?screens=${screens}`, {Authorization: `lid ${accommodation}`});
			return response;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);


const initialState = {
	status: 'idle',
	error: null,
	screens: [],
	widgets: [],
	outlets: [],
	outletLists: []
};

const webAppStructureSlice = createSlice({
	name: 'webAppStructure',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchWidgets.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchWidgets.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(fetchWidgets.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (Array.isArray(action.payload)) {
					action.payload.forEach(payloadWidget => {
						const index = state.widgets.findIndex(widget => widget.id === payloadWidget.id);
						if (index !== -1) {
							state.widgets[index] = payloadWidget;
						} else {
							state.widgets.push(payloadWidget);
						}
					});
				}
			})
			.addCase(fetchOutletList.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchOutletList.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(fetchOutletList.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (Array.isArray(action.payload)) {
					action.payload.forEach(payloadOutletList => {
						const index = state.outletLists.findIndex(outletList => outletList.id === payloadOutletList.id);
						if (index !== -1) {
							state.outletLists[index] = payloadOutletList;
						} else {
							state.outletLists.push(payloadOutletList);
						}
					});
				}
			})
			.addCase(fetchOutlets.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchOutlets.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(fetchOutlets.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (Array.isArray(action.payload)) {
					action.payload.forEach(payloadOutlet => {
						const index = state.outlets.findIndex(outlet => outlet.id === payloadOutlet.id);
						if (index !== -1) {
							state.outlets[index] = payloadOutlet;
						} else {
							state.outlets.push(payloadOutlet);
						}
					});
				}
			})
			.addCase(fetchScreens.pending, (state) => {
				state.status = 'loading'
			})
			.addCase(fetchScreens.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(fetchScreens.fulfilled, (state, action) => {
				state.status = 'succeeded';
				if (Array.isArray(action.payload)) {
					action.payload.forEach(payloadScreen => {
						const index = state.screens.findIndex(screen => screen.id === payloadScreen.id);
						if (index !== -1) {
							state.screens[index] = payloadScreen;
						} else {
							state.screens.push(payloadScreen);
						}
					});
				}
			})
			.addCase(fetchWebAppStructure.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchWebAppStructure.fulfilled, (state, action) => {
				state.status = 'succeeded';
				const index = state.screens.findIndex(s => s.screen === action.payload.screen);
				if (index !== -1) {
					state.screens[index] = {...action.payload};
				} else {
					state.screens.push({...action.payload});
				}
			})
			.addCase(fetchWebAppStructure.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
	},
});

export default webAppStructureSlice.reducer;
